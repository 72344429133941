import React, { Fragment, useState } from "react"
import starIcon from "../../assets/images/star-icon.png"
import contact from "../../assets/images/contact.png"
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses"
import { Link } from "gatsby"
import * as Sentry from "@sentry/gatsby"

const creds = {
  accessKeyId: process.env.SES_ACCESS_KEY_ID,
  secretAccessKey: process.env.SES_SECRET_ACCESS_KEY,
}

const client = new SESClient({
  region: "us-east-1",
  credentials: creds,
})

const ContactForm = () => {
  const [message, setMessage] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)

  var params = {
    Destination: {
      ToAddresses: ["stefan@beoutspoke.com", "m.jones12209@gmail.com"],
    },
    Message: {
      Body: {
        Text: {
          Charset: "UTF-8",
          Data: "Message from " + name + " / " + email + " : " + message,
        },
      },
      Subject: {
        Charset: "UTF-8",
        Data: "Test email",
      },
    },
    RuleSetName: "default-rule-set2",
    OriginalRuleSetName: "default-rule-set",
    Source: "Outspoke <stefan@beoutspoke.com>",
    ReplyToAddresses: ["stefan@beoutspoke.com"],
  }
  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const sendEmailCommand = new SendEmailCommand(params)

    try {
      return await client.send(sendEmailCommand);
    } catch (e) {
      Sentry.captureException(e)
      console.log("error sending contact email: ", e)
    } finally {
      setLoading(false)
      setSent(true)
    }
  }

  return (
    <section className="contact-area pt-150 pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            Get in Touch
          </span>
          <h3>Ready to Get Started? Have a question?</h3>
          <p>
            Let us know using the form below and we'll get back to you asap!
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12" style={{ paddingTop: 42 }}>
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: 20,
                }}
              >
                <h2>Sending your message...</h2>
                <h4>... just one second!</h4>
              </div>
            ) : (
              <>
                {sent ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingLeft: 20,
                    }}
                  >
                    <h2>Thanks for reaching out!</h2>
                    {/* <h4>We'll get back to you within 24 hours.</h4> */}
                    <br />
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <h4>Forget something?</h4>
                      <div
                        className="option-item"
                        onClick={() => setSent(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Link
                          activeClassName="active"
                          className="default-btn"
                          style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginLeft: 15,
                          }}
                        >
                          <i className="flaticon-right"></i> Reset <span></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="contact-form"
                    style={{ backgroundColor: "#e0e0e0", borderRadius: 6 }}
                  >
                    <form id="contactForm" onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              required
                              placeholder="Your name"
                              onChange={e => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              required
                              placeholder="Your email address"
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              cols="30"
                              rows="6"
                              required
                              placeholder="Write your message..."
                              onChange={e => setMessage(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button type="submit" className="default-btn">
                            <i className="flaticon-tick"></i>
                            Send Message <span></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
